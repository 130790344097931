/* eslint-disable jsx-a11y/aria-role */
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import Clients from '../clients/pages/index'
import Dashboard from '../home/pages/index'
import Details from '../home/pages/Details'
import MeasureType from '../measure-type/pages/index'
import Roles from '../roles/pages/index'
import ServiceCategory from '../service-category/pages/index'
import ServiceType from '../service-type/pages/index'
import Users from '../users/pages/index'
import KnowWay from '../knowWay/pages/index'
import PaymentMethod from '../payment-type/pages/index'
import Attendance from '../attendance/pages/index'
import Calendar from '../callendar/pages/index'
import Laundry from '../laundry/pages/index'
import AttendanceLaundry from '../attendance-laundry/pages/index'
import BirthRelatory from '../birthRelatory/pages/index'
import ClientsRelatory from '../clientsRelatory/pages/index'
import ProspectsRelatory from '../prospectsRelatory/pages/index'
import ServicesRelatory from '../servicesRelatory/pages/index'
import SchedulesRelatory from '../schedulesRelatory/pages/index'
import TeamProdutivityRelatory from '../teamProdutivityRelatory/pages/index'
import ServiceCategoriesRelatory from '../serviceCategoriesRelatory/pages/index'
import FinancialRelatory from '../financialRelatory/pages/index'
import CommissionReport from '../commissionReport/pages/index'
import Supplier from '../supplier/pages/index'
import BillingType from '../billingType/pages/index'
import Billing from '../billing/pages/index'
import Config from '../config/pages/index'
import FinancialDashboard from '../financialDashboard/pages/index'
import Company from '../companies/pages/index'
import Map from '../map/pages/index'
import AttendantMonitoring from '../attendant-monitoring/pages/index'
// import PaymentVerification from '../payment-verification/pages/index'
import Ready from '../home/pages/ReadyItems'
import ReadyLaundry from '../home/pages/ReadyItemsLaundry'
import LaundryDashboard from '../laundryDashboard/pages/index'
import Restoration from '../restoration/pages/index'
import Bank from '../bank/pages/index'
import BankReport from '../bank-report/pages/index'
import ServiceItemHistory from '../serviceItemHistory/pages/index'
import LeadsDashboard from '../dashboard-leads/pages/index'

function findPermission(roles, permissions) {
  if (!roles) return 0
  return permissions.filter(x => x.name === roles[0]).length
}

function findEmail(emails, user) {
  if (!emails) return 0
  return emails?.filter(email => user?.email === email).length
}

function findRoles(roles, userRole) {
  if (!roles) return 0
  return roles?.filter(role => userRole?.name === role).length
}

const redirectByRole = userRole => {
  if (userRole?.name === 'lavanderia')
    return <Redirect to="/laundry-dashboard" />

  return <Redirect to="/" />
}

const PrivateRouteWrapper = props => {
  const {
    permissions,
    role,
    emails,
    user,
    userRole,
    rolesAllowed,
    ...routeProps
  } = props
  if (
    findPermission(role, permissions) ||
    findEmail(emails, user) ||
    findRoles(rolesAllowed, userRole)
  ) {
    return <Route {...routeProps} />
  }

  return redirectByRole(userRole)
}

const PrivateRoute = connect(state => ({
  permissions: state.auth.permissions,
  user: state.auth.user,
  userRole: state.auth.role,
}))(PrivateRouteWrapper)

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        role={['read-dashboard']}
        path="/"
        component={Dashboard}
      />
      <PrivateRoute
        role={['read-financial-dashboard']}
        path="/financial-dashboard"
        component={FinancialDashboard}
      />
      <PrivateRoute
        role={['read-ready-items']}
        path="/details/ready"
        component={Ready}
      />
      <PrivateRoute
        role={['read-ready-items-laundry']}
        path="/details/laundry/ready"
        component={ReadyLaundry}
      />
      <Route path="/details/:id" component={Details} />
      <Route path="/details/late" component={Details} />
      <PrivateRoute role={['read-users']} path="/users" component={Users} />
      <PrivateRoute
        role={['read-know-way']}
        path="/knowWay"
        component={KnowWay}
      />
      <PrivateRoute
        role={['read-service-categories']}
        path="/service-category"
        component={ServiceCategory}
      />
      <PrivateRoute
        role={['read-measure-types']}
        path="/measure-type"
        component={MeasureType}
      />
      <PrivateRoute
        rolesAllowed={['lavanderia', 'administrador']}
        path="/roles"
        component={Roles}
      />
      <PrivateRoute
        role={['read-client']}
        path="/clients"
        component={Clients}
      />
      <PrivateRoute
        role={['read-service-types']}
        path="/service-type"
        component={ServiceType}
      />
      <PrivateRoute
        role={['read-payment-methods']}
        path="/payment-method"
        component={PaymentMethod}
      />
      <PrivateRoute
        role={['create-attendances']}
        path="/attendance"
        component={Attendance}
      />
      <PrivateRoute
        role={['read-schedule']}
        path="/calendar/:id?"
        component={Calendar}
      />
      <PrivateRoute
        role={['read-laundry']}
        path="/laundry"
        component={Laundry}
      />
      <PrivateRoute
        role={['read-birth-relatory']}
        path="/relatory-birth"
        component={BirthRelatory}
      />
      <PrivateRoute
        role={['read-clients-relatory']}
        path="/relatory-clients"
        component={ClientsRelatory}
      />
      <PrivateRoute
        role={['read-prospects-relatory']}
        path="/relatory-prospects"
        component={ProspectsRelatory}
      />
      <PrivateRoute
        role={['read-attendances-relatory']}
        path="/relatory-services"
        component={ServicesRelatory}
      />
      <PrivateRoute
        role={['read-schedule-relatory']}
        path="/relatory-schedules"
        component={SchedulesRelatory}
      />
      <PrivateRoute
        role={['read-service-category-relatory']}
        path="/relatory-service-category"
        component={ServiceCategoriesRelatory}
      />
      <PrivateRoute
        role={['read-commission-report']}
        path="/commission-report"
        component={CommissionReport}
      />
      <PrivateRoute
        role={['read-team-produtivity-relatory']}
        path="/relatory-team-produtivity"
        component={TeamProdutivityRelatory}
      />
      <PrivateRoute
        role={['read-financial-relatory']}
        path="/relatory-financial"
        component={FinancialRelatory}
      />
      <PrivateRoute
        role={['read-laundry-os']}
        path="/laundry-os"
        component={AttendanceLaundry}
      />
      <PrivateRoute
        role={['coordinates-list']}
        path="/attendant-monitoring"
        component={AttendantMonitoring}
      />
      <PrivateRoute
        role={['read-service-item-screen']}
        path="/service-item-history"
        component={ServiceItemHistory}
      />
      <PrivateRoute
        role={['read-supplier']}
        path="/supplier"
        component={Supplier}
      />
      <PrivateRoute
        role={['read-billing-type']}
        path="/billing-type"
        component={BillingType}
      />
      <PrivateRoute
        role={['read-billing']}
        path="/billing"
        component={Billing}
      />
      <PrivateRoute
        role={['update-settings']}
        path="/config"
        component={Config}
      />
      <PrivateRoute
        role={['read-companies']}
        path="/company"
        component={Company}
      />
      <PrivateRoute role={['read-laundry-map']} path="/map" component={Map} />

      <PrivateRoute
        role={['read-laundry-dashboard']}
        path="/laundry-dashboard"
        component={LaundryDashboard}
      />

      <PrivateRoute
        role={['update-restoration-items']}
        path="/restoration-items"
        component={Restoration}
      />

      <PrivateRoute
        role={['read-bank-report']}
        path="/bank-report"
        component={BankReport}
      />

      <PrivateRoute
        role={['read-dashboard-leads']}
        path="/dashboard-leads"
        component={LeadsDashboard}
      />

      <PrivateRoute role={['read-bank']} path="/bank" component={Bank} />

      {redirectByRole()}
    </Switch>
  )
}

export default React.memo(Routes)
