import {
  FETCH_ATTENDANCE_INIT,
  EDIT_ATTENDANCE,
  FETCH_HISTORIC,
  FETCH_ITEM_HISTORIC,
} from './AttendanceActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: {},
  editingAttendance: null,
  comoConheci: [],
  status: [],
  services: [],
  paymentMethods: [],
  attendants: [],
  historic: [],
  itemHistoric: [],
  lossReasonId: [],
  companies: [],
  itemStatuses: [],
  banks: [],
  suppliers: [],
  tags: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_INIT.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        comoConheci: action.payload.data.comoConheci,
        status: action.payload.data.status,
        services: action.payload.data.services,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        paymentMethods: action.payload.data.paymentMethods,
        attendants: action.payload.data.attendants,
        companies: action.payload.data.companies,
        itemStatuses: action.payload.data.itemStatuses,
        lossReasonId: action.payload.data.lossReasonId,
        banks: action.payload.data.banks,
        filters: action.payload.filters,
        suppliers: action.payload.data.suppliers,
        tags: action.payload.data.tags,
      }
    case EDIT_ATTENDANCE.SUCCESS:
      return {
        ...state,
        editingAttendance: {
          ...action.payload.data,
          final_value_discount: action.payload.data.total_with_discount,
          services: action.payload.data.services.map(_item => {
            let item = { ..._item }
            if (item?.measureType.id === 1)
              item = { ...item, quantity: parseInt(item.quantity, 10) }
            item = { ...item, technician_default: item.technician_value }
            return item
          }),
        },
      }
    case 'SAVE_FILTERS':
      return {
        ...state,
        filters: action.payload,
      }
    case 'ERASE_FILTERS':
      return {
        ...state,
        filters: {},
      }
    case FETCH_HISTORIC.SUCCESS:
      return {
        ...state,
        historic: action.payload.data,
      }
    case FETCH_ITEM_HISTORIC.SUCCESS:
      return {
        ...state,
        itemHistoric: action.payload.data,
      }
    default:
      return state
  }
}

export const getAttendanceListData = state => {
  return {
    items: state.attendance.items,
    page: state.attendance.page,
    sizePerPage: state.attendance.sizePerPage,
    totalSize: state.attendance.totalSize,
    lossReasonId: state.attendance.lossReasonId,
  }
}

export const getEditingAttendance = state => {
  return state.attendance.editingAttendance
}
